.ce-container {
  /* padding: 5vh; */
}

.client-education {
  padding: 5vh;
  max-width: 75vw;
  /* background-color: lightgray; */
  box-sizing: border-box;

}

.ce-courses {
  display: flex;
  gap: 3vw;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  /* max-width: 70vw; */
  /* align-items: center; */
}

.ce-title {
  font-family: Montserrat;
  margin-bottom: 3vh;
}

.course-card {
  width: 15vw;
  background: #f3fadc;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: flex-start;

}

.cc-picture {
  width: 100%;
  /* background-color: lightgray; */
  height: 10vw;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: contain !important;
}

.cc-info {
  background: #f3fadc;
  display: flex;
  flex-direction: column;
  gap: 1vh;
  font-family: Montserrat;
  padding: 2vh;
  /* justify-self: start; */
  /* height: 100%; */
  align-items: center;
}

.cc-title {
  font-size: 1rem;
}

.cc-desc {
  font-size: 0.7rem;
  text-align: center;
  white-space: nowrap; /* Prevents the text from wrapping to the next line */
  overflow: hidden; /* Hides any text that overflows the container */
  text-overflow: ellipsis; /* Adds an ellipsis to indicate that the text has been truncated */
  max-width: 200px;
  /* min-height: 100px; */
}

.cc-footer {
  font-size: 0.8rem;
  font-family: Montserrat;
  justify-self: flex-end;
  align-self: center;
  padding-bottom: 2vh;
}
