.service-card{
    display: flex;
    flex-direction: column;
    min-width: 17vw;
    /* flex: 1; */
    gap: 4vh;
    /* border: 1px solid black; */
    padding: 3vh 2vw;
    border-radius: 0.7rem;
    /* box-shadow: 0px 0px 10px rgba(195, 195, 195, 0.751); */
    /* justify-content: space-between; */
    background-color: #f7ead7;
    color: #054922;
    align-items: center;
    /* justify-content: center; */
}

.sc-picture{
    background: url(https://images.unsplash.com/photo-1568043625493-2b0633c7c491?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 20vh;
    margin-bottom: 1vh;

}

.sc-info{
    display: flex;
    flex-direction: column;
    gap: 1vh;
    align-items: center;
    justify-content: center;
}

.sci-title{
    text-align: center;
    font-family: "buttershine";
    font-weight: 400;
    font-size: 1.2rem;
}

.sci-desc{
    font-family: "Montserrat";
    font-weight: 500;
    text-align: center;
    font-size: 0.8rem;
}

.sc-button{
    width: max-content;
    padding: 0.8vh 1.5vw;
    font-family: "Montserrat";
    font-weight: 700;
    font-size: 0.8rem;
    background: none;
    outline: none;
    border-radius: 5vh;
    align-self: center;
    margin-top: 1vh;
}

@media(max-width:768px){
    .service-card{
        min-width: 69vw;
        box-sizing: border-box;
        padding: 3vh;
    }
}