.sideNav{
    height: 85vh;
    box-sizing: border-box;
    padding: 10vh 5vw;
    background-color: #FFF08D;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 2px solid black;
    position: fixed;
    width: 25vw;
    /* overflow-y: scroll; */
    bottom: 0;
    right: 0;
    margin-left: 1px;
}

.sn-option{
    font-family: "Montserrat";
    font-size: 1.3rem;
    cursor: pointer;
    font-weight: 500;
    padding-right: 5px;
    transition: all 0.2s;
}