.pmi_doc:hover {
  text-decoration: underline;
}

.profile_modal {
  overflow-y: scroll;
  /* margin-bottom: 5vh; */
}

.profile_modal::-webkit-scrollbar {
  display: none;
}

.pm_approve {
  margin-top: 3vh;
}

.pmi_payouts{
  display: flex;
  gap: 3vw;
  margin-top: 1vh;
  font-weight: 500;
}

.ip_head{
  font-weight: 600;
  margin-top: 2vh;
}

.initiate_payout{
  display: flex;
  align-items: center;
  margin-top: 1vh;
  gap: 1vw;
  padding-bottom: 10vh;
}

.input_payout{
  padding: 1vh;
  border: 2px solid gray;
  font-family: Montserrat;
  outline: none;
  border-radius: 0.5vh;

}

.confirm_payout{
  padding: 0.8vh 1.5vh;
  border: none;
  font-family: Montserrat;
  outline: none;
  border-radius: 0.5vh;
  background-color: green;
  color: white;
}