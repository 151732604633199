.privacy_policy{
    padding: 5vh;
}

.pp_header{
    font-family: histeagin;
    font-size: 2rem;
}

.pp_desc{
    font-family: Montserrat;
    font-size: 0.9rem;
    margin: 4vh 0;
    margin-top: 1vh;
}

.pp_secondary{
    font-family: histeagin;
}

.pp_bold{
    font-weight: 600;
}

.pp_points{
    font-family: Montserrat;
    font-size: 0.9rem;
    padding-left: 2vw;
    margin: 4vh 0;
    margin-top: 1vh;
}