.obc{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10vh;
    transform: translateY(-5vh);
}

.obc-title{
    display: flex;
    flex-direction: column;
    gap: 0.3vh;
}

.obc-title > p{
    font-family: "histeagin";
    font-size: 2.8rem;
    padding-bottom: 1vh;
    font-weight: 500;
}

.underline{
    width: 70%;
    height: 2px;
    background-color: black;
    align-self: center;
}

.choices{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30vh;
}

.choice-client, .choice-specialist{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.c-photo, .s-photo{
    height: 30vh;
    width: 30vh;
    /* height: 100%;
    width: 100%; */
    /* background-color: gray; */
    border-radius: 50%;
    /* margin-bottom: 3vh; */
    object-fit: fill;
    /* background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important; */
}

/* .c-photo{
    background: url(https://res.cloudinary.com/dhhjtuhrd/image/upload/v1691489983/client_ICON_for_Portal_cg8odb.png) !important;
}

.s-photo{
    background: url(https://res.cloudinary.com/dhhjtuhrd/image/upload/v1691489983/Provider_ICON_for_Portal_ojswvy.png) !important;
} */

.choice-name{
    font-family: "Montserrat";
    width: 100%;
    max-width: 10vw;
    padding: 1vh 1vw;
    border: 2px solid darkolivegreen;
    border-radius: 20vh;
    font-weight: 600;
    text-align: center;
}