.cp-container{
    display: flex;
    /* justify-content: space-between; */
}

.cp-preview{
    height: 83vh;
    box-sizing: border-box;
    padding: 1vh;
    display: flex;
    flex-direction: column;
    max-width: 25vw;
    position: fixed;
    left: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: 10vw;
    z-index: 99;
    border-right: 2px solid black;
}

.cp-preview::-webkit-scrollbar {
    display: none;
}

.cp-image-container{
    box-sizing: border-box;
    min-width: 10vw;
    min-height: 20vw;
    /* background-color: gray; */
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    border-radius: 1vh;
}

.cp-name-age{
    font-weight: 600;
    font-family: "Montserrat";
    margin: 1vh 0;
    font-size: 1.4rem;
}

.cp-info{
    padding: 1vh !important;
}

.cp-credentials{
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
    margin-top: 1vh;
}

.cpc-option{
    display: flex;
    align-items: center;
    gap: 0.3rem;
    font-family: "Montserrat";

}

.cp-desc{
    font-family: "Montserrat";
    min-width: 23vw;
}

.bottom-nav{
    padding: 2vh 10vh;
    box-sizing: border-box;
    border-radius: 1vh;
    background-color: white;
    box-shadow: 0px 0px 5px 5px white;
    width: 21vw;
    position: fixed;
    bottom: 3vh;
    left: 3vh;
    align-self: center;
    /* height: 10vh; */
    display: flex;
    justify-content: space-between;
}

.line{
    width: 100%;
    height: 1px;
    background-color: gray;
    margin: 2vh 0;

}

.specialist-profile{
    height: 80vh;
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    padding: 7vh 5vw;
    gap: 3vh;
    /* pa-bottom: 7vh; */
    margin-left: 21vw;
    /* border-left: 3px solid black; */
    flex: 1;
    margin-bottom: 5vh;
}

.cp-input{
    display: flex;
    align-items: center;
}

.cp-input > p{
    font-family: "Montserrat";
    font-size: 1.3rem;
    margin-right: 0.5rem;
}

.cp-submit > p{
    font-family: "Montserrat";
    font-size: 1.1rem;
    margin-right: 0.5rem;
    background-color: green;
    cursor: pointer;
    padding: 1vh 2vh;
    color: white;
    border-radius: 3px;
}

.cp-input > input{
    outline: none;
    border: 1px solid black;
    padding: 1vh 1vw;
    border-radius: 3px;
    font-family: "Montserrat";
}

label{
    font-family: "Montserrat";
    font-size: 1.3rem;
    margin-right: 0.5rem;
}

select{
    outline: none;
    border: 1px solid black;
    padding: 1vh 0.5vw;
    border-radius: 3px;
    font-family: "Montserrat";
}

.birth-fund-section{
    max-width: 40vw;
    display: flex;
    flex-direction: column;
    margin-top: 3vh;

}

.bfs-top{
    display: flex;
    flex-direction: column;
}

.bfs-title{
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 1.5rem;
    align-self: left;

}

.bfs-info{
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 0.8rem;
    align-self: left;
    text-align: left;
    margin-top: 2vh;
}

.bfs-updates{
    display: flex;
    margin-top: 3vh;
    gap: 1vw;
}

.bfsu-info{
    display: flex;
    flex-direction: column;
    flex: 2;
}

.bfsu-title{
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 1rem;
    align-self: left;
}

.bfsu-desc{
    font-family: "Montserrat";
    font-weight: 400;
    font-size: 0.8rem;
    align-self: left;
    text-align: left;
    margin-top: 1vh;
}

.bfsu-photo{
    flex: 1;
    width: 100%;
    height: 90%;
    background-color: gray;
    align-self: center;
}


.bfs-well-wishes{
    display: flex;
    flex-direction: column;
    margin-top: 3vh;
}

.bfsw-title{
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 1rem;
    align-self: left;
    /* margin-bottom: 1vh; */
}

.bfsww{
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-top: 2vh;
}

.bfsww-photo{
    min-width: 7vh;
    height: 7vh;
    background-color: gray;
    border-radius: 50%;
}

.bfsww-desc{
    font-family: "Montserrat";
    font-size: 0.75rem;
}

.bfs-bottom{
    display: flex;
    margin-top: 5vh;
    flex-direction: column;
    /* border: 1px solid black; */
    box-sizing: border-box;
    padding: 2vh;
    font-family: "Montserrat";
    margin-bottom: 5vh;
    background-color: rgba(136, 255, 0, 0.229);
    border-radius: 3vh;
}

.raised{
    font-weight: 600;
}

.bfs-progress-bar{
    max-width: 100%;
    height: 3vh;
    border-radius: 10vh;
    /* background-color: white; */
    margin-top: 1vh;
    display: flex;
    margin-bottom: 1vh;
}

.bfspb-left{
    background-color: rgba(136, 255, 0, 0.933);
    /* flex: 500; */
    height: 100%;
    /* border-radius: 10vh; */
    border-top-left-radius: 10vh;
    border-bottom-left-radius: 10vh;
}

.bfspb-right{
    /* flex: 900; */
    height: 100%;
    background-color: white;
    border-top-right-radius: 10vh;
    border-bottom-right-radius: 10vh;

}

.no-of-donations{
    font-size: 0.8rem;
}

.donate-buttons{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 1vw;
}

.donate-buttons > button{
    padding: 1vh 1vw;
    border: none;
    outline: none;
    color: Black;
    font-family: "Montserrat";
    cursor: pointer;
    font-weight: 600;
    background-color: rgb(255, 208, 0);
    border-radius: 10vh;
}

.start_donation{
    display: flex;
    flex-direction: column;
    font-family: Montserrat;
    /* padding: 3vh; */
    margin: 5vh 0;
}

.sd_head{
    margin-top: 2vh;
    margin-bottom: 0.5vh;
    font-size: 0.8rem;
    font-weight: 500;
}

.sd_input{
    padding: 1vh;
    font-family: Montserrat;
    border: 2px solid #054922;
    border-radius: 0.5vh;
}

.sd_button{
    margin-top: 2vh;
    background-color: #054922;
    color: white;
    font-family: Montserrat;
    border: none;
    font-weight: 600;
    padding: 1vh 2vh;
    border-radius: 0.5vh;
    width: max-content;
    cursor: pointer;
}

.connect_button{
    margin-top: 2vh;
    background-color: #054922;
    color: white;
    font-family: Montserrat;
    border: none;
    font-weight: 600;
    padding: 1vh 2vh;
    border-radius: 0.5vh;
    width: max-content;
    cursor: pointer;
}

.cp-logout > p{
    background-color: red !important;
}