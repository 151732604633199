.specialist-inquiries{
    margin-right: 25vw;
}

.swiper{
    width:100%;
}
.si-cards-section{
    display: flex;
    width: 75vw;
    justify-content: center;
    gap: 3vw;
    margin: 5vh 0;
}

.si-card-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
}


.see-more{
    font-family: "Montserrat";
    cursor: pointer;
}

.see-more:hover{
    text-decoration: underline;
}

.si-card{
    height: max-content;
    width: max-content;
    padding: 2vh;
    min-width: 18vw;
    /* border: 1px solid black; */
    margin-top: 2vh;
    border-radius: 2vh;
    box-shadow: 0 0 10px 5px rgb(228, 228, 228);
}

.sic-photo {
  /* box-sizing: border-box; */
  width: 100%;
  height: 24vh;
  background-color: lightgray;
  background-position:  center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-radius: 1vh;
  align-self: center;
}

.si-name-age {
  font-weight: 600;
  font-family: "Montserrat";
  margin: 2vh 0;
  margin-bottom: 1.5vh;
  font-size: 1.4rem;
}

.si-credentials {
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
  margin-top: 1vh;
  /* padding: 1vh; */
}

.sic-option {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-family: "Montserrat";
  font-size: 0.8rem;
}

.si-desc {
  font-family: "Montserrat";
  font-size: 0.8rem;
}

.sic-bottom-nav {
    display: flex;
    justify-content: center;
    margin-top: 2vh;
    gap: 20%;
}


.line {
  width: 100%;
  height: 1px;
  background-color: gray;
  margin: 2vh 0;
}

.si-buttons{
    display: flex;
    justify-content: center;
    width: 75vw;
    gap: 2vw;
    margin-bottom: 7vh;
    margin-top: 7vh;
}

.si-button {
  padding: 0.5vh 1.5vw;
  border: 3px solid black;
  border-radius: 5vw;
  font-size: 1.1rem;
  font-family: "Montserrat";
  font-weight: 600;
}

.si-steps{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5vh;
    margin-bottom: 7vh;
}

.sis-1{
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 1.4rem;
}