.quiz_modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  background-color: rgba(231, 231, 231, 0.378);
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  color: #054922;
}


.quiz_card {
  background-color: #fff;
  max-width: 50vw;
  box-sizing: border-box;
  padding: 5vh;
  border-radius: 1vh;
  border: 3px solid #054922;
}

.qm_head{
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2vh;
    font-family: histeagin;
}

.qm_head > svg{
    scale: 1.2;
    cursor: pointer;
}

.qm_desc{
    font-family: montserrat;
    font-size: 0.9rem;
    font-weight: 500;
}