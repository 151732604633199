.services-opening {
  width: 100%;
  display: flex;
}

.so-left {
  background-color: #f7ead7;
  color: #054922;
  flex: 4;
  box-sizing: border-box;
  /* height: 40vh; */
  /* border-top-right-radius: 30vh; */
  /* border-bottom-right-radius: 30vh; */
  /* margin-right: -25vh; */
  z-index: 2;
  padding: 9vh 25vw;
  /* padding-left: 7vw; */
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 3vh;
}

.so-title {
  font-family: "histeagin";
  color: #054922;
  font-size: 2.5rem;
  line-height: 2.5rem;
  font-weight: 500;
}

.so-desc {
  font-family: "Montserrat";
  color: #054922;
  font-weight: 500;
  font-size: 1rem;
}

.so-cta {
  border: none;
  outline: none;
  background-color: rgb(255, 230, 65);
  width: max-content;
  padding: 2vh 2vw;
  border-radius: 4vh;
  font-family: "Montserrat";
  text-transform: uppercase;
  font-size: 1.1rem;
  font-weight: 700;
  margin-top: 1 vh;
}

.so-right {
  /* height: 40vh; */
  background: url(https://images.unsplash.com/photo-1493894473891-10fc1e5dbd22?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 40%;
  flex: 0;
  display: none;
}

.smc-1{
  box-shadow: 10px -10px 0px 0px #edff7a;
  background-color: #050505;

}

.service-mid {
  display: flex;
  flex-direction: column;
  padding: 7vh 20vw;
}

.sm-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.7rem;
  text-align: center;
}

.sm-cards {
  display: flex;
  /* margin-top: 5vh; */
  gap: 3vw;
  /* flex-direction: column; */
}

.services-phrase {
  text-align: center;
  font-size: 1.5rem;
  padding: 1.5vh 0;
  background-color: #f7ead7;
  color: #054922;
  font-family: buttershine;
  font-weight: 400;

}

.feature_list{
  display: flex;
  /* align-items: center; */
  padding: 5vh 5vw;
  gap: 3vw;
}

.feature_card{
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3vh;
  background-color: #f7ead7;
  color: #054922;
  font-family: Buttershine;
  font-size: 1.1rem;
}

.services-main {
}

.our-services {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1vh;
  padding: 7vh 20vw;
  background-color: rgba(255, 230, 65, 0.601);
}

.os-title {
  font-family: "Montserrat";
  font-size: 1.6rem;
  font-weight: 700;
}

.os-desc {
  font-family: "Montserrat";
  font-size: 1.2rem;
  font-weight: 500;
}

.service-list-container, .m-service-list-container {
  display: flex;
  flex-direction: row;
  padding: 0 5vw;
  justify-content: space-between;
  align-items: center;
}

.m-service-list-container{
  display: none;
}

.arrow {
  /* padding:  1vw; */
  /* border-radius: 45%; */
  /* box-shadow: 0px 0px 10px rgb(195, 195, 195); */
  transform: scale(2);
  cursor: pointer;
}

.arrow > svg {
  color: #054922;
}

.services-list {
  display: flex;
  width: 77%;
  flex-wrap: nowrap;
  overflow-x: scroll;
  /* justify-content: center; */
  /* transform: translateX(-20px); */
  scrollbar-width: none;
  padding: 10vh 2vw;
  scroll-behavior: smooth;
  /* padding-left: 1vw; */
  gap: 3vw;
  /* border: 1px solid black; */
}

.services-list::-webkit-scrollbar {
    display: none;
}

.featured-specialists {
  /* background-color: rgba(255, 230, 65, 0.601); */
  padding: 7vh 0;
  display: flex;
  flex-direction: column;
  gap: 5vh;
}

.fs-info {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1vh;
  padding: 0vh 20vw;
}

.fs-title {
  font-family: "Buttershine";
  text-transform: uppercase;
  color: #054922;
  font-size: 1.6rem;
  font-weight: 700;
}

.fs-desc {
  font-family: "Montserrat";
  font-size: 1rem;
  color: #054922;
  font-weight: 500;
}

.services-owner-section {
  display: flex;
  gap: 2vw;
  padding: 0 7vw;
}

/* .services-kanisha{
    display: flex;
} */

.services-person {
  display: flex;
  gap: 1vw;
  /* align-items: center; */
  text-align: center;
  flex: 1;
  font-size: 0.9rem;
  color: #054922;
}

.person-picture {
  flex: 3;
  background-color: #fff;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.person-info-1 {
  flex: 2;
  /* text-align: right; */
  padding: 3vh 1vw 4vh 0;
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.person-info-2 {
  flex: 2;
  padding: 3vh 0 4vh 1vw;
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.person-name {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.5rem;
}

.person-desc {
  font-family: "Montserrat";
  font-weight: 500;
  /* font-size: 1rem; */
}

.bas-button {
  align-self: center;
  /* justify-self: center; */
}

.bas-button  button {
  border: none;
  outline: none;
  background-color: transparent;
  /* border: 3px solid black; */
  border-radius: 10vh;
  padding: 1.5vh 1.5vw;
  background-color: #054922;
  color: #edff7a;
  font-family: "Buttershine";
  font-weight: 400;
  /* text-transform: uppercase; */
  font-size: 1.1rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .so-left{
    padding: 3vh 5vh;
  }

  .so-title{
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 600;
  }

  .so-desc{
    font-size: 0.9rem;
  }

  .sm-cards{
    flex-direction: column !important;
    gap: 3vh;
  }

  .service-mid{
    padding: 5vh 6vh ;
  }

  .feature_list{
    flex-direction: column;
  }

  .services-phrase{
    padding: 1.5vh 3vh;
    font-size: 1.2rem;
  }

  .service-list-container{
    display: none;
  }

  .m-service-list-container{
    display: flex;
  }

  .fs-info{
    padding: 0 5vh;
  }

  .fs-title{
    font-size: 1.3rem;
  }

  .fs-desc{
    font-size: 0.9rem;
  }

  .services-owner-section{
    flex-direction: column;
    gap: 5vh;
  }

  .services-person{
    flex-direction: column;
    gap: 3vh;
  }

  .person-picture{
    min-height: 33vh;
    width: 80%;
    align-self: center;
  }

  .person-info-1{
    padding-bottom: 0;
    font-size: 0.9rem;
  }

  .person-info-2{
    padding-top: 0;
    font-size: 0.9rem;
  }

  .featured-specialists{
    gap: 1vh;
  }
  .bas-button  button {
    padding: 1.5vh 3vh;
    font-size: 1rem;
  }

  
}
