.admin-sidebar{
    display: flex;
    flex-direction: column;
    align-items: start;
    font-family: Montserrat;
    gap: 3vh;
    width: 20vw;
    box-sizing: border-box;
    background-color: rgba(255, 230, 65, 0.601);
    padding: 5vh;
    height: 85vh;
    border-left: 2px solid black;
    font-size: 1.2rem;
    position: fixed;
    bottom: 0;
    right: 0;
}

.as-option{
    display: flex;
    gap: 0.5vw;
    align-items: center;
    cursor: pointer;
    transition: all 0.1s;
}

.as-option:hover{
    font-weight: 500;    
}
