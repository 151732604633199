.quiz-container {
  background-color: rgb(255, 221, 0, 0.3);
  display: flex;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.quiz-page-11 {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 5vh 4vw;
  border-radius: 0.5rem;
  gap: 1vh;
  width: 25vw;
  /* min-width: 450px; */
  border: 3px solid #054922;
}

.qp11-title {
  font-family: "Playfair Display";
  padding-bottom: 1rem;
  text-align: center;
  font-size: 2rem;
  border-bottom: 5px solid #e19A26;
  align-self: center;
  margin-bottom: 2vh;
}

.qp11-options {
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  margin-bottom: 2vh;
  gap: 3vh;
  justify-content: space-between;
}

.range-slider{
    display: flex;
    align-items: center;
    justify-content:space-between;
    gap: 15px;
    font-size: 1.1rem;
    font-family: "Montserrat";
    font-weight: 600;
}

.qp11-options > p {
    font-size: 1.2rem;
    font-family: "Montserrat";
    font-weight: 600;
}

.range-slider > input {
  accent-color: #e19A26;
}


.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.8;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #e19A26;
  border: 3px solid #054922;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #04aa6d;
  cursor: pointer;
}

/* .qp11-option{
    min-width: 20%;
    flex: 1;
    text-align: center;
    box-sizing: border-box;
    padding: 0.7rem 0.3rem;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    font-weight: 700;
    border: 2.6px solid #054922;
    color: #054922;
    font-family: "Montserrat";
    transition: all 0.2s;
    cursor: pointer;
}

.qp11-option:hover{
    border-color: #e19A26;
    box-shadow: 0px 0px 10px rgb(162, 162, 162);
} */

.qp11-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1vh;
}

.qp11-button {
  width: 20%;
  min-width: 160px;
  max-width: 180px;
  font-family: "Montserrat";
  padding: 0.7rem 0.5rem;
  font-size: 1.1rem;
  background-color: #e19A26;
  color: #054922;
  border-radius: 0.5rem;
  font-weight: 700;
  border: 3px solid #054922;
  outline: none;
  transition: all 0.2s;
  cursor: pointer;
  /* align-self: flex-end; */
}

.qp11-button:hover {
  background-color: #054922;
  color: #e19A26;
  box-shadow: 0px 0px 10px rgb(162, 162, 162);
}

.yellow-background {
  /* border-width: 3px; */
  border-color: #e19A26;
  background-color: #e19A26;
}
