.home {
  /* height: 100%; */
  /* background-color: #F3FADC; */
}

.opening {
  font-family: "histeagin";
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  gap: 2vh;
  text-align: center;
  /* background-color: rgb(48, 48, 139); */
  /* background-color: rgb(143, 143, 143); */
  padding: 5vh 0 8vh 0;
  justify-content: space-between;
  align-items: center;
  /* border-top: 2px dashed lightgray; */
  background-color: #f7ead7;
  /* border-bottom: 2px dashed lightgray; */
  color: #054922;
}

/* .opening-left {
  display: flex;
} */

.opening .l1 {
  font-size: 2.5rem;
  font-weight: 600;
}

.opening .l2 {
  font-size: 1.2rem;
  text-align: center;
  padding: 0 20vw;
  font-family: "Montserrat";
}

.opening > button {
  margin-top: 2vh;
  font-family: "Playfair Display";
  font-size: 1.8rem;
  font-weight: 500;
  padding: 0.5rem 2rem;
  border-radius: 50vh;
  /* border: 2px solid #EEBA2B; */
  background-color: #054922;
  border: none;
  /* background-color: #FADC93; */
  color: #edff7a;
  transition: all 0.2s;
  cursor: pointer;
}

.opening > button:hover {
  box-shadow: 0px 0px 10px rgb(213, 213, 213);
  /* transform: translateY(-2px);  */
}

.opening-right {
  height: 50vh;
  width: 70vh;
  margin: 50px 0;
  /* border: 1px solid black; */
}

.opening-image {
  width: 100%;
  height: 100%;
  background: url(https://images.unsplash.com/photo-1581952975975-08cd95a728d4?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1171&q=80);
  /* border-radius: 0.5rem; */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.worked-with {
  padding: 5vh 0;
  /* border-bottom: 2px dashed lightgray; */
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 2vw;
  display: flex;
}

.ww_images {
  box-sizing: border-box;
  /* width: 100%; */
  align-items: center;
  justify-content: center;
  gap: 2vw;
  display: flex;
}

.ww_images > img {
  height: 22vh;
  object-fit: contain;
}

.worked-with > p {
  color: #054922;
  font-family: histeagin;
  font-size: 1.3rem;
  font-weight: 600;
}

.tagline {
  width: 100%;
  /* border-bottom: 2px dashed lightgray; */
  background-color: #054922;
  color: #edff7a;
  font-family: buttershine;
}

.tagline > p {
  /* font-family: "histeagin"; */
  /* font-family: buttershine; */
  font-weight: 400 !important;
  word-spacing: 3px;
  font-size: 2rem;
  text-transform: uppercase;
  text-align: center;
  padding: 25px 0;
}

.illustration_video_section {
  display: flex;
  padding: 5vh 5vw;
  align-items: center;
  justify-content: center;
  gap: 5vw;
  padding-top: 10vh;
}

.illustration_video {
  flex: 2;
  background-color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 50vw;
  max-height: 20vh;
}

.actuali-video {
  height: 50vh;
  width: 100%;
  object-fit: cover;
  border: 3px solid #054922;
}

.ivs_parent {
  padding: 1.5vh 3vh;
  border-radius: 5vh;
  background-color: #edff7a;
  color: #054922;
  cursor: pointer;
  font-family: buttershine;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  box-shadow: 5px 7px 0px 0px #054922;
  transition: all 0.2s;
}

.ivs_provider {
  padding: 1.5vh 3vh;
  border-radius: 5vh;
  background-color: #054922;
  color: #edff7a;
  cursor: pointer;
  font-family: buttershine;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  box-shadow: 5px 7px 0px 0px #edff7a;
  transition: all 0.2s;
}

.ivs_parent:hover,
.ivs_provider:hover {
  transform: translateY(-5px);
}

.m_features {
  display: none;
}

.features {
  display: flex;
  /* flex-direction: column; */
  /* justify-content: center; */
  /* box-sizing: border-box; */
  /* width: 100%; */
  padding: 7vh 10vw;
  /* gap: 20px; */
  /* height: 100%; */
  /* margin: 5vh 0; */
  /* border-bottom: 2px dashed lightgray; */
  transition: all 0.2s !important;
}

.features-head {
  display: flex;
  align-items: center;
  width: 100%;
  /* background: gray; */
}

.fh-icon {
  transform: scale(1.3) !important;
  cursor: pointer;
}

.feature-name-head {
  /* border: 1px solid black; */
}

.feature-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
  border: 1px solid white;
  padding: 1rem;
  border-radius: 0.5rem;
  transition: all 0.2s;
  height: max-content;
  transition: all 0.2s !important;

}

.feature-info:hover {
  /* border: 1px solid black; */
  box-shadow: 0px 0px 12px lightgray;
}

.feature-desc {
  font-size: 0.9rem;
  font-weight: 400;
  font-style: italic;
}

.feature-bold {
  font-weight: 700;
}

.feature-box-shadow {
  box-shadow: 0px 0px 12px rgb(183, 183, 183);
}

.provider {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  gap: 3vh;
  padding-right: 2vw;
  border-right: 1px solid black;
  transition: all 0.5s ease;
}

.client {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 3vh;

  padding-left: 2vw;
  /* border-left: 1px solid black; */
  transition: all 0.5s ease;
}

.feature-line {
  width: 1px;
  height: 80vh;
}

.title {
  text-align: center;
  font-family: "Buttershine";
  font-weight: 500;
  font-size: 1.7rem;
  /* justify-self: center; */
  margin: auto;
}

.button {
  align-self: center;
  font-family: "Buttershine";
  padding: 0.7rem 1rem;
  /* border: 2px solid black; */
  /* background-color: black; */
  /* color: white; */
  /* border: 2px solid #EEBA2B; */
  background-color: #054922;
  color: #edff7a;
  border-radius: 10rem;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s;
}

.button:hover {
  box-shadow: 0px 0px 10px rgb(213, 213, 213);
  transform: translateY(-2px);
}

.feature-body {
  flex: 1;
  display: flex;
  gap: 5vw;
  align-items: center;
}

.client-reduce-bottom-margin {
  padding-bottom: -50vh;
}

.service-options {
  display: flex;
  /* position: fixed; */
  /* display: block; */
  flex-direction: column;
  padding: 1vh 0;
  max-width: 30vw;
  font-family: "Montserrat";
  gap: 0px;
  transition: all 0.2s;
  /* max-width: 100px; */
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0s !important;
}

.so-2 {
  /* padding-left: 10vw; */
}

.service-options > p {
  transition: all 0.04s;
  cursor: pointer;
  padding: 1px;
  font-size: 0.1rem;
}

.service-options > p:hover {
  font-weight: 700;
}

.feature-picture {
  background-color: white;
  width: 100%;
  height: 70%;
  max-height: 70vh;
  /* min-width: 45vw; */
  /* box-sizing: border-box; */
  /* margin: 7vh 0; */
  /* align-self: center; */
  /* box-shadow: 0px 0px 12px gray; */
  border-radius: 0.5rem;
  flex: 1;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

/* .client-picture {
  background-color: gray;
  width: 100%;
  margin: 7vh 0;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
} */

.client .service-options {
  text-align: right;
}

.invisible {
  color: white;
  user-select: none;
}

.home-sections {
  display: flex;
  flex-direction: column;
  padding: 10vh 15vw;
  gap: 10vh;
  /* border-bottom: 2px dashed lightgray; */
}

.what-we-do {
  padding: 10vh 15vw;
  /* border-bottom: 2px dashed lightgray; */
  /* justify-content: space-between; */
  /* align-items: center; */
  box-sizing: border-box;
}

.wwd-header {
  font-size: 2.5rem;
  font-family: "histeagin";
  font-weight: 700;
  text-align: center;
  margin-bottom: 7vh;
}

.wwd-cards {
  display: flex;
  gap: 3vw;
  width: 100%;
  text-align: center;
}

.wwd-card {
  flex: 1;
  padding: 3vh 1.5vw;
  border-radius: 0.7rem;
  box-shadow: 0px 0px 10px rgb(195, 195, 195);
}

.wwd-title {
  font-size: 1.3rem;
  font-family: "Buttershine";
  /* font-weight: 200; */
  margin-bottom: 0.8rem;
}

.wwd-desc {
  font-size: 1rem;
  font-family: "Montserrat";
  font-weight: 400;
}

.meet-our-founder {
  padding: 10vh 20vw;
  /* border-bottom: 2px dashed lightgray; */
  /* justify-content: space-between; */
  /* align-items: center; */
  box-sizing: border-box;
}

.mof-header {
  font-size: 2.5rem;
  font-family: "histeagin";
  font-weight: 700;
  text-align: center;
  margin-bottom: 7vh;
}

.mof-sections {
  display: flex;
  gap: 5vw;
  width: 100%;
  /* text-align: center; */
}

.mof-kanisha-image {
  flex: 1;
  background-color: gray;
  background-image: url("/public/kanisha.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  border-radius: 0.5rem;
  width: 40%;
}

.mof-kanisha-desc {
  flex: 1;
  font-family: "Montserrat";
  /* text-align: center; */
  font-size: 1.2rem;
  font-weight: 500;
}

.review-section {
  display: flex;
  flex-direction: column;
  padding: 10vh 15vw;
  /* gap: 7vh; */
  /* border-bottom: 2px dashed lightgray; */
  color: #054922;
}

.review-title {
  align-self: center;
  font-family: "Playfair Display";
  text-align: center;
}

.review-line {
  font-family: "Playfair Display";
  font-size: 0.9rem;
  text-align: center;
  margin-top: 1vh;
}

.review-body {
  display: flex;
  gap: 4vw;
  margin-top: 7vh;
}

.reviews {
  flex: 3;
  display: flex;
  flex-direction: column;
  padding: 3vh;
  gap: 5vh;
  background-color: #edff7a;
  box-sizing: border-box;
  border: 3px solid #054922;
}

.review {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  font-family: "Raleway";
}

.reviewer-name {
  font-size: 1.2rem;
}

.review-desc {
  font-size: 0.9rem;
  font-weight: 500;
}

.review-video {
  flex: 3;
  background-color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.actual-video {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.igposts-section {
  display: flex;
  padding: 10vh 10vw;
  /* border-bottom: 2px dashed lightgray; */
  height: 40vh;
}

.big-post {
  flex: 2;
  background-color: gray;
  /* background-image: url(https://instagram.fccu27-1.fna.fbcdn.net/v/t51.288…zGcDgfU-uFQ9wex6GIaTtg&oe=63474BAA&_nc_sid=30a2ef); */
  /* background-image: url(https://images.unsplash.com/photo-1665119210660-76c544928746?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.small-posts {
  flex: 3;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.row-1,
.row-2 {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.small-post {
  border: 1px solid black;
  flex: 1;
  /* width: 33%; */
  /* height: 50%; */
}

.home-cta {
  display: flex;
  flex-direction: column;
  padding: 5vh 15vw;
  align-items: center;
  gap: 3vh;
  /* border-bottom: 2px dashed lightgray; */
}

.home-cta > p {
  font-family: "histeagin";
  font-weight: 600;
  font-size: 2rem;
  /* align-self: center; */
}

.home-cta > button {
  width: max-content;
  font-family: "Playfair Display";
  font-size: 1.8rem;
  font-weight: 500;
  padding: 0.5rem 2rem;
  border-radius: 0.7rem;
  border: 2px solid #eeba2b;
  background-color: #f3fadc;
  color: #357a2c;
  transition: all 0.2s;
  cursor: pointer;
}

.home-cta > button:hover {
  box-shadow: 0px 0px 10px rgb(213, 213, 213);
}

@media (max-width: 768px) {
  .opening {
    padding: 5vh 3vh;
  }

  .opening .l2 {
    padding: 0;
  }

  .worked-with {
    flex-direction: column;
  }

  .ww_images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3vh;
    width: 100%;
    /* background-color: whitesmoke; */
  }

  .ww_images > img {
    width: 17vh;
    height: 17vh;
    width: max-content;
    /* background-color: gray; */
  }

  .worked-with > p {
    font-size: 2rem;
    /* background-color: gray; */
  }

  .opening .l1 {
    font-size: 1.8rem;
  }

  .opening .l2 {
    font-size: 1rem;
  }
  .opening > button {
    font-size: 1.1rem;
  }

  .tagline {
    padding: 2vh 3vh;
    box-sizing: border-box;
  }

  .tagline > p {
    font-size: 1.5rem;
  }

  .illustration_video_section {
    flex-direction: column;
    padding: 5vh 3vh;
    gap: 3vh;
  }
  .illustration_video {
    max-height: 23vh;
  }

  .actuali-video {
    height: 23vh;
  }

  .features {
    display: none;
  }

  .m_features {
    display: flex;
    flex-direction: column;
    gap: 5vh;
    font-family: Montserrat;
    padding: 5vh 3vh;
  }

  .m_feature {
    display: flex;
    flex-direction: column;
    gap: 1vh;
  }

  .m_feature > img {
    object-fit: contain;
    align-self: center;
    height: 20vh;
    margin-top: 1vh;
  }

  .review-section {
    padding: 5vh 3vh;
  }

  .review-title {
    font-size: 1.5rem;
  }

  .review-body {
    flex-direction: column;
    margin-top: 3vh;
  }
}
