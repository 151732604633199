.empty_region{
    /* background-color: lightgray; */
    width: 75vw;
    box-sizing: border-box;
    height: 85vh;
    display: flex;
    position: fixed;
    top: 15vh;
    left: 0;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    /* padding-top: 10vh; */
    justify-content: center;
    background-color: whitesmoke;
    /* z-index: 999999 !important; */
    font-family: Montserrat;
}