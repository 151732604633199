.cfm-container{
    overflow: hidden !important;
    overflow-x: hidden;
    /* max-width: 50vw; */
}

.cfm-select{
  margin-left: 20vw;
  margin-top: 3vh;
}


.cfm-swipe-container{
    max-width: 60vw;
    display: flex;
    height: 20vh;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;

}

.cfm-card{
    height: max-content;
    width: 15vw;
    min-width: 15vw;
    padding: 2vh;
    /* border: 1px solid black; */
    border-radius: 2vh;
    box-shadow: 0 0 10px 5px rgb(228, 228, 228);
    background-color: #fff;
    transform: scale(1.2);
    margin-top: -1vh;
}

.cfmc-photo {
  /* box-sizing: border-box; */
  width: 100%;
  height: 20vh;
  background-color: lightgray;
  border-radius: 1vh;
  align-self: center;
}

.cfm-cards-container{
  overflow: hidden !important;
}

.cfm-name-age {
  font-weight: 600;
  font-family: "Montserrat";
  margin: 2vh 0;
  margin-bottom: 1vh;
  font-size: 1rem;
}

.cfm-credentials {
  display: flex;
  flex-direction: column;
  gap: 0.3vh;
  margin-top: 0.5vh;
  /* padding: 1vh; */
}

.cfmc-option {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-family: "Montserrat";
  font-size: 0.7rem;
}

.cfmc-bottom-nav {
    display: flex;
    justify-content: center;
    margin-top: 2vh;
    gap: 20%;
}

.cfmc-view-profile{
  text-align: center;
  padding-top: 2vh;
  margin-top: 2vh;
  padding-bottom: 1vh;
  font-family: Montserrat !important;
  border-top: 1px solid lightgray;
  font: 0.8rem sans-serif;
  cursor: pointer;
}

.cfmc-view-profile:hover{
  text-decoration: underline;
}


/* .card {
    position: relative;
    background-color: #fff;
    width: 350px;
    height: 500px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    background-size: cover;
    background-position: center;
  }
  
  .card h3 {
    position: absolute;
    bottom: 0;
    margin: 10px;
    color: #fff;
  }*/

  .swipe{
    position: absolute;
  } 

  .see_suggestions{
    /* align-self: center; */
    margin-left: 30vw;
    margin-top: 2vh;
    border: none;
    padding: 0.8vh 1.5vh;
    background-color: green;
    font-family: Montserrat;
    color: white;
    font-weight: 600;
    border-radius: 0.3vh;
    cursor: pointer;
  }