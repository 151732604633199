.client-need-sub{
    /* background-color: lightgray; */
    max-width: 75vw;
    box-sizing: border-box;
    height: 85vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding-top: 30vh;
}

.client-need-sub > h2{
    font-family: Montserrat;
    font-size: 1.8rem;
}

.client-need-sub > button{
    padding: 1vh 2vh;
    background-color: green;
    border: none;
    border-radius: 0.5vh;
    color: white;
    font-weight: 500;
    font-family: Montserrat;
    margin-top: 2vh;
    cursor: pointer;
    font-size: 1rem;
}