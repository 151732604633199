.admin_messages{
    background-color: whitesmoke;
    width: 80vw;
    box-sizing: border-box;
    padding: 5vh;
    font-family: Montserrat;
    min-height: 84vh;
}

.am_messages{
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.message_row{
    width: 70vw;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 3vh;
    gap: 1vh;
    border-radius: 1vh;
}