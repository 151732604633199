.ccp_main{
    /* background-color: gray; */
    max-width: 74vw;
    display: flex;
}

.ccp_people{
    /* flex: 2; */
    border-right: 2px solid lightgray;
    height: 85vh;
    /* height: 100% !important; */
    position: fixed;
    left: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    /* padding-bottom: 10vh; */
    width: 21vw;
    overflow-y: scroll !important;
}

.ccp_chat{
    flex: 5;
    /* overflow-y: scroll; */
    /* position: relative; */
    padding: 1vw;
    display: flex;
    padding-right: 1.2vw;
    flex-direction: column;
    gap: 1vh;
    width: 100%;
    max-width: 51vw;
    margin-left: 21vw;
    /* background-color: gray; */
    font-family: Montserrat;
    min-height: 73vh;
    padding-bottom: 10vh;
}

.ccp_rest{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding-top: 30vh;
    min-height: 1vh;
    max-height: max-content !important;
    overflow-y: hidden !important;
}

.ccp_rest > svg{
    font-size: 3rem;
}

.ccp_chat_received, .ccp_chat_sent{
    padding: 1.5vh 2.5vh;
    box-sizing: border-box;
    border-radius: 0.5vh;
    /* flex: 1; */
    max-height: max-content !important;
}

.ccp_chat_sent{
    /* position: absolute; */
    right: 1vw;
    /* top: 10px; */
    max-width: 90%;
    background-color: rgba(255, 230, 65, 0.601);
    text-align: right;
    align-self: flex-end;
    width: max-content;

}

.ccp_chat_received{
    /* position: absolute; */
    left: 1vw;
    /* top: 10px; */
    width: max-content;
    max-width: 90%;
    background-color: whitesmoke;
}

.ccp_chat_input{
    /* justify-self: end; */
    position: fixed;
    bottom: 1vw;
    width: 51vw;
    align-self: center;
    border: none;
    outline: none;
    padding: 2vh 1vh;
    box-sizing: border-box;
    border-radius: 1vh;
    box-shadow: 0px 0px 15px 5px rgb(228, 228, 228);
}

.people_card_chat{
    display: flex;
    align-items: center;
    gap: 1vw;
    padding: 2vh;
    cursor: pointer;
    border-bottom: 2px solid lightgray;
    font-family: Montserrat;
}

.people_card_chat:hover{
    background-color: whitesmoke;
}

.pcc_picture{
    height: 40px;
    width: 40px;
    border-radius: 100px;
    object-fit: cover;
    
}

