.stripe_success{
    height: 100vh;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Montserrat;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    /* gap: 10vh; */
}

.stripe_icon{
    font-size: 4rem;
}

.stripe_home{
    background: green;
    padding: 0.8vh 1.5vh;
    border: none;
    outline: none;
    color: white;
    font-weight: 600;
    font-family: Montserrat;
    border-radius: 0.5vh;
    margin-top: 2vh;
    cursor: pointer;
}