.fp-opening {
  width: 100%;
  display: flex;
  min-height: 40vh;
  /* flex-direction: column !important; */
}

.fpo-left {
  background-color: #edff7a;
  flex: 3.5;
  box-sizing: border-box;
  color: #054922;
  font-family: histeagin;
  font-size: 2rem;
  /* height: 40vh; */
  border-top-right-radius: 30vh;
  border-bottom-right-radius: 30vh;
  margin-right: -25vh;
  z-index: 2;
  padding: 9vh 15vw;
  padding-left: 7vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 3vh;
}

.fpo-right {
  background: url(https://res.cloudinary.com/dhhjtuhrd/image/upload/v1686604997/happy_providers_ix0sr0.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 40%;
  flex: 5;
}

.fp-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5vh 0;
  /* padding-bottom: 10vh; */
}

.fpm-title {
  font-family: Buttershine;
  align-self: center;
}

.fpm-features-section {
  display: flex;
  width: 100%;
  justify-content: center;
  /* align-items: center; */
  gap: 3vw;
  margin-top: 5vh;
  padding-bottom: 5vh;
}

.fpm-feature {
  max-width: 25vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
  color: #054922;
}

.fpmf-photo {
  width: 100%;
  height: 35vh;
  object-fit: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.fpmf-title {
  font-family: Montserrat;
  font-size: 1.3rem;
  font-weight: 600;
  text-transform: uppercase;
}

.fpmf-desc {
  font-family: Montserrat;
  font-size: 0.9rem;
  font-weight: 500;
  text-align: center;
}

.fpm-bold-sentence {
  width: 100%;
  text-align: center;
  font-family: Playfair Display;
  font-size: 1.3rem;
  font-weight: 700;
  background-color: #edff7a;
  color: #054922;
  margin-top: 5vh;
  padding: 3vh 0;
  text-transform: uppercase;
}

.provider-features-section {
  display: flex;
  padding: 5vh 10vw;
  gap: 3vw;
  /* border-top: 2px solid gray; */
  /* border-bottom: 2px solid gray; */
}

.pfs-left {
  flex: 3;
}

.pfs-right {
  flex: 4;
  /* width: 100%; */
  /* height: 70%; */
  /* background-position: center;
    background-size: cover;
    background-repeat: no-repeat; */
  /* height: 70vh; */
  align-self: center;
}

.pfs-right > img {
  width: 100%;
  height: 60vh;
  object-fit: contain;
  object-position: center;
  /* align-self: center; */
}

.pfs-right {
  /* width: 50vw; */
}

.pfs-service-options {
  display: flex;
  /* position: fixed; */
  display: block;
  flex-direction: column;
  padding: 1vh 0;
  font-family: "Montserrat";
  gap: 0px;
  transition: all 0.2s;
  /* max-width: 100px; */
  font-size: 1.1rem;
  font-weight: 500;
  transition: all 0s !important;
}

.pfs-service-options > p {
  transition: all 0.04s;
  cursor: pointer;
  padding: 1px;
}

.pfs-service-options > p:hover {
  font-weight: 700;
}

.feature-name-head {
  /* border: 1px solid black; */
}

.pfs-feature-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
  border: 1px solid white;
  padding: 1rem;
  border-radius: 0.5rem;
  transition: all 0.2s;
  height: max-content;
}

.pfs-feature-info:hover {
  /* border: 1px solid black; */
  box-shadow: 0px 0px 12px lightgray;
}

.pfs-feature-desc {
  font-size: 0.9rem;
  font-weight: 400;
  font-style: italic;
}

.pfs-feature-bold {
  font-weight: 700;
}

.pfs-feature-box-shadow {
  box-shadow: 0px 0px 12px rgb(183, 183, 183);
}

.provider-statistic-section {
  display: flex;
  padding: 5vh 25vw;
  justify-content: center;
  /* align-items: center; */
  background-color: #edff7a;
  gap: 5vw;
}

.pss-statistic {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  font-family: Buttershine;
  text-align: center;
  color: #054922;
  min-width: 20vw;
}

.pss-statistic > h1 {
  font-weight: 400;
  font-size: 3rem;
}

.pss-statistic > p {
  font-family: histeagin;
  font-weight: 600;
}

.provider-reviews-video-section {
  padding: 10vh 5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.provider-reviews-video-section > video {
  width: 60vw;
}

.provider-reviews-video-section > p {
  font-family: Montserrat;
  margin-top: 3vh;
  text-align: center;
}

.jap_button_container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: gray; */
}

.jap_button {
  font-family: "Buttershine";
  padding: 0.7rem 1rem;
  /* border: 2px solid black; */
  /* background-color: black; */
  /* color: white; */
  /* border: 2px solid #EEBA2B; */
  background-color: #054922;
  color: #edff7a;
  border-radius: 10rem;
  font-weight: 400;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.2s;
}

.m-provider-features-section{
  display: none;
}

@media (max-width: 768px) {
  .fp-opening {
    flex-direction: column !important;
    min-height: 30vh;
    box-sizing: border-box;
  }

  .fpo-left {
    font-size: 1.3rem;
    gap: 2vh;
    padding: 5vh 3vh;
    margin-right: 0;
    border-radius: 0;
  }

  .fpo-right {
    display: none;
    flex: 1;
    scale: 0;
  }

  .fpm-features-section {
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    /* background-color: gray; */
    align-items: center;
    gap: 5vh;
  }

  .fpm-feature {
    max-width: 80vw;
  }

  .fpmf-photo {
    height: 25vh;
  }

  .provider-features-section{
    display: none;
  }

  .m-provider-features-section{
    display: flex;
    flex-direction: column;
    gap: 5vh;
    font-family: Montserrat;
    padding: 5vh 3vh;
  }

  .m_feature{
    display: flex;
    flex-direction: column;
    gap: 1vh;
  }

  .m_feature > img{
    object-fit: contain;
    align-self: center;
    height: 20vh;
    margin-top: 1vh;
  }

  .provider-statistic-section{
    flex-direction: column;
    gap: 5vh;
    padding: 5vh;
  }

}
