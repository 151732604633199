.reviews_modal_container{
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: rgba(211, 211, 211, 0.43);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: montserrat;
    z-index: 100;
    position: fixed;
    top: 0  ;
    left: 0;
}

.reviews_modal{
    display: flex;
    flex-direction: column;
    gap: 2vh;
    padding: 3vh 4vh;
    width: 30vw;
    box-sizing: border-box;
    height: 50vh;
    background-color: #fff;
    overflow-y: scroll;
    border-radius: 1vh;
}

.rm_head{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1vh;
}

.rm_card{
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
}

.rm_name{
    font-weight: 600;
}