.education {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.e-header {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  align-items: center;
  background: #054922;
  color: #edff7a;
  padding: 5vh 15vw;
  padding-top: 7vh;
  gap: 3vh;
}

.e-header > p {
  font-family: histeagin;
  font-size: 1.2rem;
  text-align: center;
  font-weight: 500;
  line-height: 1.8rem;
}

.e-header > p > span {
  font-size: 3rem;
  margin: 0 1vw;
}

.e-header > button {
  padding: 1.5vh 5vw;
  /* padding-top: 2vh; */
  border-radius: 50vh;
  border: none;
  outline: none;
  font-size: 1.1rem;
  font-family: buttershine;
  background-color: #f3fadc;
  cursor: pointer;
}

.eq-text {
  font-family: Montserrat;
  align-self: center;
  margin-top: 5vh;
}

.e-query {
  width: 300px;
  margin-top: 2vh;
  align-self: center;
  padding: 1vh;
  font-size: 0.9rem;
  font-family: Montserrat;
  outline: none;
  border: 2px solid #054922;
}

.edu-bold {
  font-size: 3rem;
  font-weight: 600;
}

.e-course-list {
  padding: 7vh 10vw;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  gap: 7vh;
}

.ecl-card {
  width: 17vw;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
}

.eclc-picture {
  width: 100%;
  background-color: lightgray;
  height: 15vw;
  background-position: center !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  border: 3px solid green;
}

.eclc-info {
  background: #f3fadc;
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 2vh;
  font-family: Montserrat;
  padding: 2vh;
  align-items: center;
}

.eclc-title {
  font-size: 1.5rem;
  text-align: center;
}

.eclc-desc {
  font-size: 0.9rem;
  text-align: center;
  flex: 1;
}

.e-vm {
  padding: 1.5vh 5vw;
  /* padding-top: 2vh; */
  border-radius: 50vh;
  border: none;
  outline: none;
  font-size: 1.1rem;
  font-family: buttershine;
  background-color: #f3fadc;
  cursor: pointer;
  align-self: center;
  margin-bottom: 5vh;
}

.eclc-footer {
  /* justify-self: baseline; */
  text-align: center;
}

.eclc_button {
  padding: 0.8vh 1.5vh;
  border: none;
  background-color: green;
  font-family: Montserrat;
  font-weight: 600;
  color: white;
  cursor: pointer;
}

.subscribe_modal {
  height: 100vh;
  width: 100%;
  background-color: rgba(128, 128, 128, 0.263);
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  font-family: Montserrat;
  display: flex;
  align-items: center;
  justify-content: center;
}

.subscribe_modal > p {
  padding: 5vh;
  background-color: #fff;
  border-radius: 0.5vh;
}

.subscribe_modal > p >span{
    color: green;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 500;
}