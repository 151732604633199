.acc-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.admin-create-course {
  padding: 7vh;
  font-family: Montserrat;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  width: 80vw;
}

.acc-title {
  font-size: 2rem;
  margin-bottom: 5vh;
}

.acc-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}

.acc-inputs > input {
  border: 1px solid gray;
  padding: 2vh 1vw;
  border-radius: 1vh;
  width: 30vw;
  outline: none;
  font-family: Montserrat;
}

.poster-upload {
  border: 1px solid gray;
  padding: 1.5vh 1vw;
  border-radius: 1vh;
  width: 25vw;
  height: max-content;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
  outline: none;
  font-family: Montserrat;
  display: inline-block;
  position: relative;
}


.poster-upload > input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

.acc-inputs > button {
  padding: 2vh 1vw;
  border-radius: 1vh;
  background-color: rgba(255, 230, 65, 0.834);
  color: Black;
  width: 15vw;
  outline: none;
  font-weight: 600;
  font-size: 0.9rem;
  border: none;
  font-family: Montserrat;
  transition: all 0.2s;
}

.acc-inputs > button:hover {
  background-color: green;
  color: white;
  cursor: pointer;
}

.poster_preview{
  max-width: 30vw;
  object-fit: contain;
}

.add_tag_container{
  /* max-width: 25vw; */
  border: 1px solid gray;
  padding: 2vh 1vw;
  border-radius: 1vh;
  width: 30vw;
  display: flex;
  justify-content: space-between;
  outline: none;
  font-family: Montserrat;
}

.add_tag_input{
  /* padding: 2vh 1vw; */
  outline: none;
  flex: 1;
  font-family: Montserrat;
  border: none;
}

.add_tag_button{
  font-size: 0.8rem;
  background-color: lightgray;
  padding: 0.5vh 1vh;
  border-radius: 0.3vh;
  cursor: pointer;
  font-weight: 600;
}

.tags{
  display: flex;
  gap: 1vw;
}

.tags > div{
  background-color: lightgray;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  gap: 0.5vw;
  padding: 0.8vh 1.5vh;
  border-radius: 30vh;
}

.tags > div > svg{
  cursor: pointer;
}