.subscribe{
    display: flex;
    width: 100vw;
    height: 83vh;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding-top: 25vh;
}

.sub_title{
    font-family: histeagin;
    font-size: 2rem;
}

.sub_price{
    font-family: histeagin;
    /* font-weight: 500; */
}

.sub_button{
    color: white;
    background-color: green;
    padding: 1vh 2vh;
    font-size: 0.9rem;
    border: none;
    outline: none;
    border-radius: 0.5vh;
    margin-top: 2vh;
    font-family: montserrat;
    /* font-family: histeagin; */
    cursor: pointer;
}