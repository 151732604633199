.admin_pl {
  font-family: Montserrat;
  padding: 5vh;
}

.apl_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 74vw;
}

.apl_header > button{
    background-color: darkgreen;
    color: white;
    font-weight: 600;
    font-family: montserrat;
    padding: 0.8vh 1.5vh;
    border-radius: 0.5vh;
    border: none;
    outline: none;
    cursor: pointer;
}

.apl_table {
  width: 74vw;
  /* background-color: lightblue; */
  margin-top: 5vh;
}

.apl_table_head > tr > th,
.apl_table_body > tr > td {
  text-align: start;
  padding-right: 5vw;
  padding-bottom: 1vh;
  padding-top: 1vh;
}

.apl_table_head > tr,
.apl_table_body > tr {
  /* display: flex; */
  width: 100%;
  /* background-color: gray; */
}

.apl_table_body > tr > td {
  border-top: 1px solid lightgray;
}
