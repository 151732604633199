.navbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 25px 100px;
  font-family: "Playfair Display";
  font-weight: 600;
  box-sizing: border-box;
  z-index: 999;
  position: sticky;
  top: 0;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid lightgray;
}

.logo {
  /* color: red;
    margin-right: 30px;
    font-size: 25px; */
  width: 12vw;
  height: 8vh;
  background: url("https://res.cloudinary.com/dhhjtuhrd/image/upload/v1686773069/Logo_V2_Logo_black_1_diwfwk.png");
  /* background-color: black; */
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.options {
  color: black;
  display: flex;
  /* flex: 1; */
  align-items: center;
  justify-self: end;
  font-size: 1.5rem;
}

.options > p {
  cursor: pointer;
}

.sign-in-button {
  color: #357a2c;
  font-weight: 700;
}

.nav_menu {
  display: none;
}

.nav_modal_container {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    padding: 0 5vw !important;
    /* background-color: gray; */
  }

  .logo {
    height: 8vh;
    width: 15vh;
  }

  .options {
    display: none;
  }

  .nav_menu {
    display: block;
  }

  .nav_modal_container {
    height: 100vh;
    width: 100%;
    position: fixed;
    background-color: rgba(211, 211, 211, 0.322);
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .nav_modal {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    padding: 2vh 3vh;
    width: 70%;
    box-sizing: border-box;
    border-radius: 1vh;
    align-items: center;
    font-size: 1.5rem;
  }

  .nm_item {
    padding-top: 1.1vh;
    padding-bottom: 1.2vh;
    border-bottom: 1px solid lightgray;
    width: 100%;
    text-align: center;
  }

  .sign-in-button {
    border-bottom: none;
  }
}
