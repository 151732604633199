.ac-container{
    display: flex;
    justify-content: space-between;
}

.admin-courses{
    width: 80vw;
    box-sizing: border-box;
    padding: 7vh;
    font-family: Montserrat;
}

.ac-title{
    font-size: 2rem;
    margin-bottom: 3vh;
}

.table-row{
    display: flex;
    gap: 1vw;
    padding: 1vh 0;
    align-items: center;
    border-bottom: 1px solid gray;
}

.table-row > p{
    flex: 3;
    /* background: gray; */
    white-space: nowrap; /* Prevents the text from wrapping to the next line */
    overflow: hidden; /* Hides any text that overflows the container */
    text-overflow: ellipsis; /* Adds an ellipsis to indicate that the text has been truncated */
    max-width: 200px;
    padding: 1vh 0;
}

.table-row:hover{
    background-color: whitesmoke;
    cursor: pointer;
}

.table-row-head{
    font-weight: 600 !important;
}

.tr-title, .tr-creator{
    flex: 4 !important;
}

.course-action{
    flex: 4;
    /* background-color: gray; */
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1vw;
}

.course-action > button{
    border: none;
    padding: 1vh 1vw;
    border-radius: 3px;
    outline: none;
    font-family: Montserrat;
    font-weight: 600;
    transition: all 0.2s;
    cursor: pointer;
}

.view-lectures:hover{
    background-color: green;
    color: white;
}

.delete-course:hover{
    background-color: red;
    color: white;
}