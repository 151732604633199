.quiz-container{
    background-color: #edff7a;
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.qd{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 5vh 4vw;
    border-radius: 0.5rem;
    gap: 3vh;
    width: 35vw;
    min-width: 450px;
    border: 3px solid #054922;
}

.qd-title{
    font-family: "Playfair Display";
    padding-bottom: 0.5rem;
    font-size: 2rem;
    border-bottom: 5px solid #e19A26;
    align-self: center;
}

.qd > p{
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 1.3rem;
    text-align: center;
}

.qd-button{
    width: 30%;
    font-family: "Montserrat";
    padding: 0.7rem 0.5rem;
    font-size: 1.1rem;
    background-color: #e19A26;
    color: #054922;
    border-radius: 0.5rem;
    font-weight: 700;
    border: 3px solid #054922;
    outline: none;
    transition: all 0.2s;
    cursor: pointer;
    align-self: center;
}

.qd-button:hover{
    background-color: #054922;
    color: #e19A26;
    box-shadow: 0px 0px 10px rgb(162, 162, 162);
    /* align-self: center; */
}