.client_documents {
    font-family: montserrat;
    /* background-color: lightgray; */
    width: 75vw;
    box-sizing: border-box;
    padding: 5vh;
  }
  
  .cd_title {
    margin-bottom: 3vh;
  }
  
  .add_doc_button {
    padding: 1vh 2vh;
    background-color: green;
    color: white;
    font-family: montserrat;
    font-weight: 600;
    border: none;
    border-radius: 0.5vh;
    cursor: pointer;
  }
  
  .file-input {
    display: none;
  }
  
  .custom-file-upload {
    display: inline-block;
    padding: 1vh 2vh;
    background-color: green;
    color: white;
    font-family: montserrat;
    font-weight: 600;
    border: none;
    border-radius: 0.5vh;
    cursor: pointer;
    font-size: 0.9rem;
    transition: all 0.2s;
  }
  /* 
  .custom-file-upload:hover {
    background-color: rgb(0, 89, 255);
  } */
  
  .cd_file{
      display: flex;
      padding-top: 2vh;
      gap: 1vw;
      align-items: center;
  }
  
  .cd_file > button{
      /* background-color: #fff; */
    background-color: rgb(0, 136, 255);
    padding: 0.8vh 1.5vh;
    font-size: 0.8rem;
    cursor: pointer;
    margin-left: 1vw;
    color: white;
    font-weight: 600;
    font-family: Montserrat;
    border: none;
    border-radius: 0.5vh;
  
  }
  
  .cd_all{
    display: flex;
    flex-direction: column;
    gap: 1vh;
    margin-bottom: 3vh;
  }
  
  .cd_all > p:hover{
    cursor: pointer;
    text-decoration: underline;
  }