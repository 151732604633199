.ca-container{
    display: flex;
    justify-content: space-between;
}

.client-acc{
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10vh 10vw;
}

.ca-input{
    display: flex;
    align-items: center;
}

.ca-input > p{
    font-family: "Montserrat";
    font-size: 1.3rem;
    margin-right: 0.5rem;
}

.ca-input > input{
    outline: none;
    border: 1px solid black;
    padding: 1vh 1vw;
    border-radius: 3px;
    font-family: "Montserrat";
}

label{
    font-family: "Montserrat";
    font-size: 1.3rem;
    margin-right: 0.5rem;
}

select{
    outline: none;
    border: 1px solid black;
    padding: 1vh 0.5vw;
    border-radius: 3px;
    font-family: "Montserrat";
}
