.footer {
  display: flex;
  justify-content: center;
  padding: 5vh 15vw;
  font-family: "Playfair Display";
  font-size: 1.2rem;
  font-weight: 500;
  gap: 3vw;
  color: #054922;
}

.footer > p, .footer > a {
  cursor: pointer;
}

.footer > p:hover, .footer > a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer{
    flex-direction: column;
    padding: 3vh;
  }

}