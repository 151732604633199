.chat_warning{
    /* background-color: lightgray; */
    width: 74vw;
    box-sizing: border-box;
    height: 85vh;
    display: flex;
    position: fixed;
    top: 15.5vh;
    /* left: 0; */
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    /* padding-top: 10vh; */
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.21);
    /* z-index: 999999 !important; */
    font-family: Montserrat;
}

.warning_container{
    width: 50%;
    padding: 3vh;
    border-radius: 1vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
    text-align: center  ;
}

.warn_head{
    color: green;
    font-weight: 700;

}

.warn_desc{
    font-weight: 400;
    font-size: 0.8rem;
}

.warn_button{
    font-weight: 600;
    color: white;
    background-color: green;
    padding: 0.8vh 1.5vh;
    font-size: 0.8rem;
    border: none;
    border-radius: 0.5vh;
    font-family: montserrat;
    cursor: pointer;
    margin-top: 1vh;
}