.client_help{
    padding: 5vh;
    padding-top: 5vh;
    font-family: Montserrat !important;
    width: 75vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: gray; */
    gap: 1vh;
}

.client_help > p{
    margin-bottom: 3vh;
}

.ch_submit{
    background-color: green;
    padding: 1vh 2vh;
    border: none;
    color: white;
    font-family: Montserrat;
    border-radius: 0.5vh;
    font-weight: 600;
    margin-top: 1vh;
    cursor: pointer;
}

.ch_input_container > textarea{
    width: 40vw;
    padding: 1vh;
    border: none;
    outline: none;
    border: 2px solid green;
    font-family: Montserrat;
    border-radius: 0.3vh;
    /* margin-top: 7vh; */
}


.ch_input_container > input{
    width: 40vw;
    padding: 1vh;
    border: none;
    outline: none;
    border: 2px solid green;
    font-family: Montserrat;
    border-radius: 0.3vh;
}

.ch_input_container{
    margin-top: 2vh;
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.ch_input_container > p{
    font-size: 0.9rem;
    font-weight: 600;
}