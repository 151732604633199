.order_modal {
    overflow-y: scroll;
    /* margin-bottom: 5vh; */
    background-color: white;
    font-family: Montserrat !important;
    width: 75vw;
    height: 85vh;
    box-sizing: border-box;
    position: fixed;
    top: 15vh;
    z-index: 99;
    padding: 5vh;
    cursor: auto;
  }
  
  .order_modal::-webkit-scrollbar {
    display: none;
  }

  .om_head{
    display: flex;
    justify-content: space-between;
  }

  .om_head > svg{
      cursor: pointer;
  }

  .om_specialist{
    display: flex;
    gap: 1vw;
  }