.input_modal {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    display: flex;
    background-color: rgba(211, 211, 211, 0.337);
    align-items: center;
    justify-content: center;
    z-index: 10000;
    font-family: histeagin !important;
  }
  
  .input_card {
    width: 40vw;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7vh;
    border: 10px solid darkgreen;
    gap: 3vh;
  }
  
  .inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
  }
  
  .im_title {
    color: darkgreen;
    font-weight: 700;
    font-size: 2.5rem;
    text-align: center;
    font-family: histeagin;
  }
  
  .im_p {
    font-size: 1.1rem;
    text-align: center;
    font-weight: 600;
    /* font-family: Montserrat; */
  }
  
  .check {
    display: flex;
    gap: 10px;
  }
  
  .inputs > input {
    padding: 1vh 2vw;
    border: none;
    outline: none;
    text-align: center;
    font-weight: 600;
    background-color: rgb(229, 253, 92);
    color: darkgreen;
    font-size: 1rem;
    font-family: histeagin !important;
    width: 20vw;
    box-sizing: border-box;

  }
  
  .im_button {
    padding: 1vh 2vw;
    border: none;
    outline: none;
    text-align: center;
    font-weight: 600;
    background-color: rgb(229, 253, 92);
    color: darkgreen;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.2s;
    font-family: histeagin;
    width: 20vw;
    box-sizing: border-box;
  }
  
  .im_button:hover {
    background-color: darkgreen;
    color: rgb(229, 253, 92);
  }
  
  .check > input {
    accent-color: darkgreen;
  }
  
  .link_to_instagram {
    color: darkgreen;
    /* text-decoration: underline; */
    cursor: pointer;
  }
  
  .mbt_logo {
    width: 40%;
    object-fit: contain;
  }
  
  @media (max-width: 768px) {
    .home_page {
      background-color: #edff7a;
    }
    .input_card {
      width: 90vw;
      box-sizing: border-box;
      padding: 3vh;
    }
    .im_title {
      font-size: 1.5rem;
    }
    .im_p {
      font-size: 1rem;
    }
    .inputs > input {
      padding: 1vh 1.5vw;
      font-size: 0.9rem;
      min-width: 50vw !important;
    }
    .im_button {
      font-size: 0.9rem;
      min-width: 50vw !important;
    }}