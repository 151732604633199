.quiz_results{
    padding: 5vh;
    font-family: Montserrat !important;
    max-width: 65vw;
    /* background-color: gray; */
}

.quiz_results > h2{
    margin-bottom: 3vh;
}

.quiz_results > p{
    margin-bottom: 2vh;
}

.quiz_results > ul{
    padding-left: 5vh;
}

.qr_button{
    padding: 0.8vh 1.5vh;
    background-color: green;
    color: white;
    border-radius: 0.5vh;
    border: none;
    font-family: Montserrat;
    margin-top: 3vh;
    font-weight: 600;
    cursor: pointer;
}