.client-inquiries {
  margin-right: 25vw;
  
}

.ci-cards-section {
  /* padding:2vh 0; */
  width: 75vw;
  /* justify-content: center; */
  /* gap: 3vw; */
  margin: 5vh 0;
  box-sizing: border-box;
  /* padding-left: 5vh; */
  /* margin-left: 2vh; */
}

.ci-card-section {
  padding:2vh 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1vh;
  padding-left: 1vh;
}

.see-more {
  font-family: "Montserrat";
  cursor: pointer;
  margin-top: 1vh;
}

.see-more:hover {
  text-decoration: underline;
}

.ci-card {
  height: max-content;
  width: 20vw;
  padding: 2vh;
  /* border: 1px solid black; */
  border-radius: 2vh;
  box-shadow: 0 0 10px 5px rgb(228, 228, 228);
}

.cic-photo {
  /* box-sizing: border-box; */
  width: 100%;
  height: 24vh;
  background-color: lightgray;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  border-radius: 1vh;
  align-self: center;
}

.ci-name-age {
  font-weight: 600;
  font-family: "Montserrat";
  margin: 2vh 0;
  margin-bottom: 1.5vh;
  font-size: 1.4rem;
}

.ci-credentials {
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
  margin-top: 1vh;
  /* padding: 1vh; */
}

.cic-option {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-family: "Montserrat";
  font-size: 0.8rem;
}

.ci-desc {
  font-family: "Montserrat";
  font-size: 0.8rem;
}

.cic-bottom-nav {
  display: flex;
  justify-content: center;
  margin-top: 2vh;
  gap: 20%;
}

.line {
  width: 100%;
  height: 1px;
  background-color: gray;
  margin: 2vh 0;
}

.ci-buttons {
  display: flex;
  justify-content: center;
  width: 75vw;
  gap: 2vw;
  margin-bottom: 7vh;
  margin-top: 7vh;
}

.ci-button {
  padding: 0.5vh 1.5vw;
  border: 3px solid black;
  border-radius: 5vw;
  font-size: 1.1rem;
  font-family: "Montserrat";
  font-weight: 600;
}

.ci-steps {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5vh;
  margin-bottom: 7vh;
}

.cis-1 {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 1.4rem;
}
