.course-modal{
    position: absolute;
    background:white;
    width: 100vw;
    height: 100vh;
    top: 0;
    box-sizing: border-box;
    padding: 5vh 5vw;
    font-family: Montserrat;
}

.cm-top{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 7vh;
}



.cm-lecture-box{
    display: flex;
    padding: 2vh 2vw;
    justify-content: space-between;
    /* border: 1px solid black; */
    border-radius: 5px;
    margin-bottom: 3vh;
    box-shadow: 0px 0px 10px 5px rgb(236, 236, 236);
    align-items: center;
}

.cmlb-left>h4{
    margin-bottom: 1vh;
}

.cm-main{
    display: flex;
}

.cmm-left{
    flex: 3;
    margin-right: 5vw;
}

.cmm-left>h2{
    margin-bottom: 3vh;
}

.cmm-left>h3{
    margin-bottom: 3vh;
}

.cmm-right{
    flex: 2;
}
.cmm-right > h2{
    margin-bottom: 5vh;
}

.cmr-inputs{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
}

.cmr-input{
    border: 1px solid gray;
    padding: 2vh 1vw;
    border-radius: 1vh;
    width: 25vw;
    outline: none;
    font-family: Montserrat;
}



.lecture-upload{
    border: 1px solid gray;
    padding: 1.5vh 1vw;
    border-radius: 1vh;
    width: 20vw;
    height: max-content;
    text-align: center;
    font-size: 1rem;
    cursor: pointer;
    font-weight: 600;
    outline: none;
    font-family: Montserrat;
    display: inline-block;
    position: relative;
}

.lecture-upload> input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
  }

  .cmr-inputs > button {
    padding: 2vh 1vw;
    border-radius: 1vh;
    background-color: rgba(255, 230, 65, 0.834);
    color: Black;
    width: 15vw;
    outline: none;
    font-weight: 600;
    font-size: 0.9rem;
    border: none;
    font-family: Montserrat;
    transition: all 0.2s;
  }
  
  .cmr-inputs > button:hover {
    background-color: green;
    color: white;
    cursor: pointer;
  }
  