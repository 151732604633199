@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {
  font-family: 'histeagin';
  src: local('histeagin'), url(./fonts/histeagin.ttf) format('truetype');
}
@font-face {
  font-family: 'Buttershine';
  src: local('Buttershine'), url(./fonts/Buttershine.otf) format('opentype');
}

*{
  padding: 0;
  margin: 0;
}
a{
  text-transform: none;
  text-decoration: none;
  color:inherit;
}

