.course-page{
    display: flex;
    font-family: Montserrat;
}

.lecture-box{
    display: flex;
    gap: 1vw;
    align-items: center;
    justify-content: start;
    cursor: pointer;
    padding: 2vh 1vw;
    border-bottom: 1px solid lightgray;
}

.cp-left{
    width: 80vw;
    box-sizing: border-box;
    /* padding: 5vh; */
    /* padding-right: 5vw; */
}

.cp-right{
    width: 20vw;
    /* background:gray; */
    box-sizing: border-box;
    padding: 2vh 0;
    height: 85vh;
    position: fixed;
    bottom: 0;
    right: 0;
    overflow-y: scroll;
    border-left: 1px solid lightgray;
    background-color: #fff;
    scrollbar-width: none;
}
.cp-right::-webkit-scrollbar {
    display: none;
  }

.cp-video{
    width: 100%;
}

.cp-video > video{
    width: 79vw;
    height: 80vh;
    background: #000;
}

.cp-info{
    padding: 5vh;
    padding-right: 10vw;
}

.cp-lecture-title{
    margin-bottom: 2vh;
}