.birth_fund_page {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 80vh;
  align-items: center;
  padding: 10vh;
  gap: 5vw;
  font-family: montserrat !important;
}

.bfp_left {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  font-weight: 600;
}

.bfp_left > img {
  height: 40vh;
  width: 40vh;
  object-fit: cover;
  object-position: center;
  margin-bottom: 1vh;
}

.bfp_green {
  color: green;
}

.bfp_right {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2vh;
  align-items: center;
  text-align: center;
}

.bfp_title {
  font-weight: 700;
  font-size: 1.5rem;
}

.bfp_fund_details {
  padding: 5vh;
  background-color: #edff7a;
  /* background-color: #fff; */
  display: flex;
  flex-direction: column;
  height: max-content;
  max-height: 50vh;
  border-radius: 1vh;
  box-shadow: 0px 0px 10px 10px whitesmoke;
  padding-bottom: 5vh !important;
  overflow: hidden;
}

.bfp_progress_bar {
  width: 20vw;
  /* min */
  height: 1.2vh;
  background-color: #edff7a;
  margin: 1vh 0;
  display: flex;
  border-radius: 10vh;
  /* border: 1px solid #054922; */
}

.bfp_amount_raised {
  font-size: 0.8rem;
}

.bfp_amount_raised > span {
  font-weight: 500;
  font-size: 1.1rem;
  margin-right: 3px;
}

.pb_left {
  background-color: #054922;
  height: 100%;
  border-radius: 10vh;
}

.pb_right {
  /* background-color: #edff7a; */
  background-color: whitesmoke;
  height: 100%;
  border-top-right-radius: 10vh;
  border-bottom-right-radius: 10vh;
}

.bfp_share,
.bfp_donate {
  padding: 1vh;
  border: none;
  background-color: #054922;
  color: white;
  font-family: Montserrat;
  font-weight: 600;
  cursor: pointer;
  margin: 0.5vh;
  border-radius: 0.5vh;
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  justify-content: center;
}

.bfp_donations {
  display: flex;
  flex-direction: column;
  /* gap: 2vh; */
  margin-top: 3vh;
  height: 50%;
  overflow-y: scroll;
}

.bfp_donation {
  display: flex;
  gap: 1vw;
  align-items: center;
  padding: 1vh 0;
  margin-right: 3vh;
  border-top: 1px solid #054922;
}

.bfpd_info > p {
  display: flex;
  font-size: 0.8rem;
  padding: 0.3vh;
  align-items: center;
}

.bfp_rd {
  font-size: 0.8rem;
  font-weight: 600;
  padding-bottom: 2vh;
}

.bfp_donations::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.bfp_donations::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px white;
  border-radius: 10px;
}

/* Handle */
.bfp_donations::-webkit-scrollbar-thumb {
  background: #054922;
  border-radius: 10px;
}
