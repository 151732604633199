.cbt-container{
    padding-left: 3vh;
}

.client-birth-team{
    margin-right: 25vw;
}

.cbt-cards-section{
    display: flex;
    width: 75vw;
    justify-content: center;
    gap: 3vw;
    margin: 5vh 0;
}

.cbt-card-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
}


.see-more{
    font-family: "Montserrat";
    cursor: pointer;
}

.see-more:hover{
    text-decoration: underline;
}

.cbt-card{
    height: max-content;
    width: max-content;
    padding: 2vh;
    /* border: 1px solid black; */
    border-radius: 2vh;
    box-shadow: 0 0 10px 5px rgb(228, 228, 228);
    /* padding-left: 5vh; */
}

.cbtc-photo {
  /* box-cbtzing: border-box; */
  width: 100%;
  height: 24vh;
  background-color: lightgray;
  border-radius: 1vh;
  align-self: center;
}

.cbt-name-age {
  font-weight: 600;
  font-family: "Montserrat";
  margin: 2vh 0;
  margin-bottom: 1.5vh;
  font-size: 1.4rem;
}

.cbt-credentials {
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
  margin-top: 1vh;
  /* padding: 1vh; */
}

.cbtc-option {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-family: "Montserrat";
  font-size: 0.8rem;
}

.cbt-desc {
  font-family: "Montserrat";
  font-size: 0.8rem;
}

.cbtc-bottom-nav {
    display: flex;
    justify-content: center;
    margin-top: 2vh;
    gap: 20%;
}


.cbt-options{
    display: flex;
    padding: 7vh 10vw;
    flex-direction: column;
    align-items: right;
    gap: 5vh;
}

.cbt-option{
    font-family: "Montserrat";
    font-size: 1.4rem;
    font-weight: 500;
}

.cbt-care-team{
    display: flex;
    gap: 2vw;
    align-items: center;
}

.cbt-ct-avatars{
    display: flex;
    gap: 1vw;
}

.cbt-ct-avatar{
    height: 10vh;
    width: 10vh;
    border-radius: 100%;
    background-color: lightgray;
}


.cart_finalize{
    padding: 0.8vh 1.5vh;
    border: none;
    outline: none;
    background-color: green;
    color: white;
    font-family: montserrat;
    font-weight: 600;
    margin-left: 2vh;
    border-radius: 0.5vh;
    cursor: pointer;
}