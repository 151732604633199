.sb-container{
    /* display: flex; */
}

.specialist-business{
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 25vw;
}

.sb-logo{
    height: 20vh;
    width: 20vw;
    background: url("/public/nav_logo.svg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    align-self: center;
}

.sb-main-stuff{
    display: flex;
    padding: 0 7vw;
    justify-content: space-between;
    align-items: center;
    margin-top: 5vh;
    gap: 2vw;
}

.sb-main-stuff > div{
    flex: 1;
    font-family: "Montserrat";
    font-weight: 600;
    font-size: 1.2rem;
    text-align: center;
}

.sb-reviews{
    /* margin-top: 3vh; */
    padding: 7vh;
    display: flex;
    flex-direction: column;
}

.sbr-title{
    font-family: "Montserrat";
    font-size: 1.3rem;
    font-weight: 600;
    margin-bottom: 3vh;
}

.sb-review{
    display: flex;
    flex-direction: column;
    margin-bottom: 3vh;
}

.sbr-info{
    display: flex;
    gap: 1rem;
    align-items: center;
}

.sbr-photo{
    min-width: 10vh;
    height: 10vh;
    background-color: gray;
    border-radius: 50%;
}

.sbr-desc{
    font-family: "Montserrat";
    font-size: 0.9rem;
}

.sbr-buttons{
    display: flex;
    gap: 4vw;
    align-self: center;
    margin-top: 1vh;
}

.sbr-button{
    padding: 0.5vh 1.5vw;
    border: 2px solid black;
    border-radius: 5vw;
    font-size: 0.9rem;
    font-family: "Montserrat";
    font-weight: 600;
}