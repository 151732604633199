.home-section{
    display: flex;
    /* padding: 7vh 15vw; */
    height: 100%;
    gap: 5vw;
    align-items: center;
}

.hsec-left{
    flex: 3;
    display: flex;
    flex-direction: column;
    gap: 3vh;
}

.hsec-title{
    font-family: "Montserrat";
}

.hsec-desc{
    font-family: "Montserrat";
    font-weight: 500;
    font-size: 1.1rem;
}

.section-image{
    flex: 4;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* width: 100%; */
    height: 45vh;
    background-color: gray;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}