.choose_reason{
    /* background-color: lightgray; */
    width: 75vw;
    box-sizing: border-box;
    height: 85vh;
    display: flex;
    position: fixed;
    top: 15vh;
    left: 0;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    /* padding-top: 10vh; */
    justify-content: center;
    background-color: rgba(128, 128, 128, 0.21);
    /* z-index: 999999 !important; */
    font-family: Montserrat;
}

.cr_container{
    width: 50%;
    padding: 3vh;
    border-radius: 1vh;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
    text-align: center  ;
}

.cr_head{
    color: green;
    font-weight: 700;
    font-size: 1.1rem;
}

.reason_container{
    display: flex;
    align-items: center;
    gap: 0.5vw;
    margin: 1vh 0;
    /* font-size: 0.9rem; */
}

.reason_container > p{
    text-align: justify;
}

.warn_desc{
    font-weight: 400;
    font-size: 0.8rem;
}

.cr_buttons{
    display: flex;
    align-items: center;
    gap: 1vw;
}



.cr_button{
    font-weight: 600;
    color: white;
    background-color: green;
    padding: 0.8vh 1.5vh;
    font-size: 0.8rem;
    border: none;
    border-radius: 0.5vh;
    font-family: montserrat;
    cursor: pointer;
    margin-top: 1vh;
}

.cr_red{
    background-color: red;
}