.admin-quiz-statistics-container {
  /* background-color: gray; */
  box-sizing: border-box;
  width: 79vw;
  padding: 5vh;
  font-family: montserrat;
}

.admin-quiz-statistics-title {
  margin-bottom: 3vh;
}

.quiz-question{
    margin-top: 2vh;
}

.quiz-question {
  font-weight: 600;
  margin-bottom: 1vh;
}

.quiz-stat > tr > td {
  padding-right: 1vh;
}
