.profile_modal {
  background-color: white;
  font-family: Montserrat !important;
  width: 75vw;
  height: 85vh;
  box-sizing: border-box;
  position: fixed;
  top: 15vh;
  z-index: 99;
  padding: 5vh;
  display: flex;
  gap: 5vh;
}

.pm_picture {
  height: 15vw;
  width: 15vw;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin-bottom: 1vh;
}

.pm_info {
  padding-top: 3vh;
}

.pmi_head {
  color: green;
  font-size: 1rem;
  font-weight: 600;
}

.pmi_info {
  color: black;
  font-size: 1.1rem;
  font-weight: 500;
  margin-top: 0.5vh;
}

.pm_right {
  flex: 1;
}

.pr_head {
  font-size: 1.5rem;
  display: flex;
  width: 100%;
  /* background-color: gray; */
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
}

.pm_specialty {
  color: green;
  font-weight: 600;
  margin-top: 1vh;
}

.pm_chat_button, .pm_add, .pm_reject {
  background-color: green;
  padding: 0.8vh 1.5vh;
  border-radius: 0.5vh;
  color: white;
  font-weight: 600;
  margin-top: 2vh;
  border: none;
  font-family: Montserrat;
  cursor: pointer;
}

.pm_add{
  margin: 0;
}



.pm_buttons {
  display: flex;
  align-items: center !important; 
  /* background-color: #151515; */
  margin: 1vh 0;
  justify-content: center;
  width: 100%;
  /* background-color: gray; */
  /* margin-top: 2vh; */
  gap: 1vw;
}

.pm_buttons > button{
  margin: 0;
}

.pm_bottom{
  border-top: 1px solid lightgray;
  margin-top: 2vh;
  padding-top: 2vh;
  display: flex;
  /* width: 100%; */
  flex-direction: column;
  align-items: center;
}

.pm_reject{
    background-color: red;
}

.pm_calendar{
  display: flex;
  align-items: center !important;
  /* background-color: black; */
}

.pminp_in{
  font-family: Montserrat;
}

.pm_buttons{
  display: flex;
  align-items: center;
  gap: 1vw;
}

.pm_inputs{
  display: flex;
  align-items: center;
  gap: 1vh;
  margin: 1vh 0;
}

.pminp_head{
  color: green;
  font-weight: 600;
}

.pminp_head > span{
  color: black;
  font-weight: 500;
  margin-left: 1vw;
}

.pm_contract{
  display:flex;
  flex-direction: column !important;
  align-items: center;
  margin: 1vh 0;
  gap: 2vh;
}

.contract_link{
  color: green;
  font-weight: 600;
}

.contract_link > a{
  color: black;
  font-weight: 400;
}

.add_review{
  display: flex;
  align-items: center;
  gap: 1vw;
}

.review_input{
  padding: 1vh;
  outline: none;
  border-radius: 0.5vh;
  border: 2px solid gray;
  font-family: Montserrat;
  width: 30vw;
}

.add_review_button{
  padding: 1vh;
  outline: none;
  border-radius: 0.5vh;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 0.8rem;
  font-weight: 600;
  background-color: green;
  font-family: Montserrat;
}