.signup_page {
  height: 85vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  box-sizing: border-box;
  padding-top: 10vh;
  font-family: Montserrat;
}
.signup_title{
    font-family: Buttershine;
    font-weight: 400;
    font-size: 1.7rem;
    margin-bottom: 5vh;
}

.signup_card {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 5vh;
  border: 3px solid green;
  border-radius: 1vh;
  gap: 2vh;
}

.sp_input_container {
  display: flex;
  flex-direction: column;
  /* gap: 1vh; */
}

.sp_input_container > p {
  font-size: 0.9rem;
  font-weight: 600;
}

.sp_input_container > input {
  width: 20vw;
  border: none;
  font-family: Montserrat;
  padding: 1vh 0;
  border-bottom: 2px solid lightgray;
  outline: none;
}

.sp_input_container > input:focus {
  border-bottom: 2px solid green;

}

.sp_submit{
    padding: 1vh 2vh;
    font-family: montserrat;
    font-weight: 600;
    color: white;
    background-color: green;
    border: none;
    outline: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 1vh;
}

.redirect{
  font-size: 0.9rem;
  margin-top: 2vh;
  font-weight: 500;
}

.redirect > span{
  color: green;
  cursor: pointer;
  /* font-weight: bold; */
}

.agree{
  font-size: 0.8rem;
  margin-top: 3vh;
  font-weight: 500;
}

.agree > span{
  color: green;
  cursor: pointer;
  /* font-weight: bold; */
}