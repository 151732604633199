.sm-card {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 2vw;
  background-color: #edff7a;
  /* border-radius: 1rem; */
  /* border: 1px solid black; */
  /* box-shadow: 0px 0px 10px rgb(195, 195, 195); */
  box-shadow: 10px -10px 0px 0px #054922;
  color: #054922;
  flex: 1;
}

.smc-picture {
  height: 40vh;
  width: 100%;
  margin-bottom: 3vh;
  align-self: center;
  /* border-radius: 0.7rem; */
}

.smc-info {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.smc-title {
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 1.1rem;
}

.smc-desc {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 1rem;
}


@media(max-width:768px) {
  .sm-card{
    padding: 2vh;
  }

  .smc-title{
    font-size: 1rem;
  }

  .smc-desc{
    font-size: 0.9rem;
  }
}