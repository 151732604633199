.impact {
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #054922;
  /* align-items: center; */
}

.impact-header {
  width: 100%;
  padding: 5vh 0;
  background-color: #edff7a;

  font-family: histeagin;
  font-size: 4rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: center;
}

.i-info {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  padding: 5vh 12vw;
  text-align: center;
  font-family: Montserrat;
}

.i-signature {
  background-image: url("/public/kfsignature.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 10vw;
  width: 15vw;
  /* display: flex; */
  align-self: center;
  margin-top: 5vh;
}

.i-owner-details {
  font-family: Montserrat;
  font-weight: 500;
  font-size: 1.1rem;
  text-align: center;
}

.i-footer {
  width: 100%;
  padding: 3vh 0;
  background-color: #edff7a;
  font-family: histeagin;
  font-size: 4rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  text-align: center;
}

@media (max-width: 768px) {
  .impact-header {
    font-size: 2rem;
    padding: 3vh 0;
    box-sizing: border-box;
  }

  .i-info {
    padding: 3vh 4vh;
    font-size: 0.9rem;
    text-align: justify;
  }

  .i-signature {
    width: 100%;
    min-height: 20vh;
    object-fit: contain !important;
    margin: 3vh 0;
  }

  .i-footer {
    font-size: 2rem;
    padding: 3vh;
    box-sizing: border-box;
  }
}
