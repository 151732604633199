.small_screen_container {
  display: none;
}

/* CSS for the body when the modal is active */
/* .body--modal-active {
    overflow: hidden;
  } */
  

@media (max-width: 768px) {
  .small_screen_container {
    box-sizing: border-box;
    min-height: 100vh;
    /* height: 100vh; */
    /* width: 100vw; */
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: histeagin;
    background-color: rgb(229, 253, 92);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    overflow: hidden !important;
  }

  .small_screen {
    background-color: #fff;
    padding: 3vh;
    border: 5px solid #054922;
    width: 80%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 600;
    gap: 2vh;
    color: #054922;
  }

  .ss_button {
    font-family: histeagin;
    background-color: #054922;
    padding: 0.8vh 1.5vh;
    border-radius: 0.5vh;
    color: rgb(229, 253, 92);
    font-weight: 500;
    border: none;
    outline: none;
  }
}
