.specialist-clients{
    margin-right: 25vw;
}
.swiper{
    width:100%;
}

.sc-cards-section{
    display: flex;
    width: 75vw;
    justify-content: center;
    gap: 3vw;
    margin: 5vh 0;
}

.sc-card-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
}


.see-more{
    font-family: "Montserrat";
    cursor: pointer;
}

.see-more:hover{
    text-decoration: underline;
}

.sc-card{
    height: max-content;
    width: max-content;
    padding: 2vh;
    /* border: 1px solid black; */
    border-radius: 2vh;
    width: 20vw;
    margin-top: 2vh;
    box-shadow: 0 0 10px 5px rgb(228, 228, 228);
}

.scc-photo {
  /* box-sczing: border-box; */
  width: 100%;
  height: 24vh;
  background-color: lightgray;
  border-radius: 1vh;
  align-self: center;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
}

.sc-name-age {
  font-weight: 600;
  font-family: "Montserrat";
  margin: 2vh 0;
  margin-bottom: 1.5vh;
  font-size: 1.4rem;
}

.sc-credentials {
  display: flex;
  flex-direction: column;
  gap: 0.5vh;
  margin-top: 1vh;
  /* padding: 1vh; */
}

.scc-option {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-family: "Montserrat";
  font-size: 0.8rem;
}

.sc-desc {
  font-family: "Montserrat";
  font-size: 0.8rem;
}

.scc-bottom-nav {
    display: flex;
    justify-content: center;
    margin-top: 2vh;
    gap: 20%;
}


.sc-options{
    display: flex;
    padding: 7vh 10vw;
    flex-direction: column;
    align-items: right;
    gap: 5vh;
}

.sc-option{
    font-family: "Montserrat";
    font-size: 1.4rem;
    font-weight: 500;
}

.sc-care-team{
    display: flex;
    gap: 2vw;
    align-items: center;
}

.sc-ct-avatars{
    display: flex;
    gap: 1vw;
}

.sc-ct-avatar{
    height: 10vh;
    width: 10vh;
    border-radius: 100%;
    background-color: lightgray;
}
