.quiz-container{
    background-color: #edff7a;
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.quiz-page-13{
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 5vh 4vw;
    border-radius: 0.5rem;
    gap: 1vh;
    width: 25vw;
    /* min-width: 450px; */
    border: 3px solid #054922;
}

.qp13-title{
    font-family: "Playfair Display";
    padding-bottom: 1rem;
    font-size: 1.7rem;
    text-align: center;
    border-bottom: 5px solid #e19A26;
    align-self: center;
    margin-bottom: 2vh;
}

.qp13-options{
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
    margin-bottom: 2vh;
    gap: 3vh;
    justify-content: space-between;
}

.qp13-option{
    min-width: 20%;
    flex: 1;
    text-align: center;
    box-sizing: border-box;
    padding: 0.7rem 0.3rem;
    font-size: 1.2rem;
    border-radius: 0.5rem;
    font-weight: 700;
    border: 2.6px solid #054922;
    color: #054922;
    font-family: "Montserrat";
    transition: all 0.2s;
    cursor: pointer;
}

.qp13-option:hover{
    border-color: #e19A26;
    box-shadow: 0px 0px 10px rgb(162, 162, 162);
}

.qp13-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1vh;
}

.qp13-button{
    width: 20%;
    min-width: 160px;
    max-width: 180px;
    font-family: "Montserrat";
    padding: 0.7rem 0.5rem;
    font-size: 1.1rem;
    background-color: #e19A26;
    color: #054922;
    border-radius: 0.5rem;
    font-weight: 700;
    border: 3px solid #054922;
    outline: none;
    transition: all 0.2s;
    cursor: pointer;
    /* align-self: flex-end; */
}

.qp13-button:hover{
    background-color: #054922;
    color: #e19A26;
    box-shadow: 0px 0px 10px rgb(162, 162, 162);

}

.yellow-background{
    /* border-width: 3px; */
    border-color: #e19A26;
    background-color: #e19A26;

}