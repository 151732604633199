.sp-container{
    display: flex;
    /* justify-content: space-between; */
}

.sp-preview{
    height: 83vh;
    /* box-sizing: border-box; */
    padding: 1vh;
    padding-right: 2vh;
    display: flex;
    flex-direction: column;
    max-width: 23vw;
    position: fixed;
    left: 0;
    bottom: 0;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-right: 10vw;
    z-index: 99;
    border-right: 2px solid black;
    
}

.sp-preview::-webkit-scrollbar {
    display: none;
}

.finalize-headshot{
    padding: 1vh 2vh;
    font-family: Montserrat;
    color: white;
    font-weight: 600;
    background-color: green;
    border: none;
    border-radius: 0.5vh;
    cursor: pointer;
}

.sp-image-container{
    box-sizing: border-box;
    min-width: 10vw;
    min-height: 20vw;
    background-color: lightgray;
    background-size: cover !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    border-radius: 1vh;
}

.sp-name-age{
    font-weight: 600;
    font-family: "Montserrat";
    margin: 1vh 0;
    font-size: 1.4rem;
}

.sp-info{
    padding: 1vh;
}

.sp-credentials{
    display: flex;
    flex-direction: column;
    gap: 0.5vh;
    margin-top: 1vh;
}

.spc-option{
    display: flex;
    align-items: center;
    gap: 0.3rem;
    font-family: "Montserrat";

}

.sp-desc{
    font-family: "Montserrat";
    min-width: 23vw;
}

.bottom-nav{
    padding: 2vh 10vh;
    box-sizing: border-box;
    border-radius: 1vh;
    background-color: white;
    box-shadow: 0px 0px 5px 5px white;
    width: 21vw;
    position: fixed;
    bottom: 3vh;
    left: 3vh;
    align-self: center;
    /* height: 10vh; */
    display: flex;
    justify-content: space-between;
}

.line{
    width: 100%;
    height: 1px;
    background-color: gray;
    margin: 2vh 0;

}

.specialist-profile{
    /* height: 80vh; */
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* padding: 7vh 5vw; */
    gap: 3vh;
    /* background-color: black; */
    /* pa-bottom: 7vh; */
    margin-left: 21vw;
    padding-bottom: 5vh !important;
    /* border-left: 3px solid black; */
    flex: 1;
    /* margin-bottom: 5vh; */
}

.sp-input{
    display: flex;
    align-items: center;
}

.sp-input > p{
    font-family: "Montserrat";
    font-size: 1.3rem;
    margin-right: 0.5rem;
}

.sp-input > input{
    outline: none;
    border: 1px solid black;
    padding: 1vh 1vw;
    border-radius: 3px;
    font-family: "Montserrat";
}

label{
    font-family: "Montserrat";
    font-size: 1.3rem;
    margin-right: 0.5rem;
}

select{
    outline: none;
    border: 1px solid black;
    padding: 1vh 0.5vw;
    border-radius: 3px;
    font-family: "Montserrat";
}

.sp-submit-button{
    padding: 1vh 2vh;
    border: none;
    background-color: green;
    color: white;
    font-family: montserrat;
    font-weight: 600;
    border-radius: 0.5vh;
    width: max-content;
    font-size: 0.9rem;
    cursor: pointer;
    /* margin-bottom: 5vh; */
}

.white-space{
    color: white;
    font-size: 2rem;
    pointer-events: none;
}

.sp-logout {
    background-color: red !important;
}

.changep_button{
    background-color: green;
    color: white;
    padding: 0.8vh 1.5vh;
    border: none;
    outline: none;
    border-radius: 0.5vh;
    font-family: Montserrat;
    font-weight: 600;
}