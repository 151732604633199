.loading_screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.158);
    z-index: 1999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    /* scroll-behavior: none; */
  }
  
  .loader {
    border: 10px solid whitesmoke; /* Light grey */
    border-top: 10px solid gray; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1.5s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  @media (max-width: 768px) {
    .loading_screen{
      width: 132vw;
      height: 132vh;
    }
  }
  